body {
  margin: 0;
  background-color: #202124;
  color: #d5d5d5;
  font-family: 'Raleway', Segoe UI, Tahoma, sans-serif;
}

a {
  text-decoration: none;
  color:#019EEB;
}

input, button, .button {      
  background-color: rgb(174, 186, 195);
  border: rgb(59, 63, 67);
  border-radius: 0.4em;
  display: inline-block;  
  
  &.primary, &.secondary {
    font-size: 1em;
    padding: 1em;
    color: white;
    font-weight: bold;
  }

  &.primary {    
    background-color: #019EEB;      
  }

  &.secondary {
    background-color: #009E1B;
  }
}

.welcome {
  display: flex;
  justify-content: center;
  align-items: center;

  .container {    
    text-align: center;
    padding: 1em;
  }  

  .headerImage {
    max-width: 80vw;
  }

  @media screen and (min-width: 769px) {
    .container {
      width: 60vw;
    }    
  }

  h1 {
    margin: 0;
  }

  .emphasis {
    font-weight: 600;
  }
}

.magazineViewer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;  

  header {
    background-color: rgb(50, 54, 57);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;

    input, button {
      height: 2.5em;
      &.square {
        width: 2.5em;        
      }    
    }

    .scaling {
      position: absolute;
      left: 10%;
      top: 20px;
      right: 10%;
      text-align: center;

      span {
        display: inline-block;
        margin: 0 1em;
      }
    }

    .controls {
      display: flex;
      flex-direction: row;
      align-items: center;

      .meta {
        flex-grow: 1;
        font-size: 1.5em;
        .title {
          font-size: inherit;
          margin: 0;              
          font-weight: 700;
        }

        .date {
          color:rgb(166, 177, 185);
        }
      }          

      
    }
  }

  &__pageList {
    overflow-x: hidden !important;
  }

  &__container {
    position: relative;
    flex-grow: 1;
    
    &__load {
      margin-top: 1em;
      color: white;
    }

    
    .page-container {
      display: flex;
          flex-direction: column;
          align-items: center;
    }

      .react-pdf {
        &__Document {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        &__Page {
          max-width: calc(~'100% - 2em');
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
          margin: 1em;

          canvas {
            max-width: 100%;
            height: auto !important;
          }
        }

        &__message {
          padding: 20px;
          color: white;
          text-align: center;
        }
      }    
  }
}

.touch-onboarding {
  display: block;
  text-align: center;
  padding: 1em;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    margin: 0.5em;
  }
}

.progress {  
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3000;
  .bar {
    height: 3px;
    background-color: #019EEB;
    width: 50%;
  }
}

.bothAxisCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookieConsent {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background:rgb(50, 54, 57);
  color: white;
  padding: 0.5em;
  display: flex;
  align-items: center;
  flex-direction: column;

  color: #cfcfcf;

  > * {
    margin: 0.2em;
  }

  button, .button {
    flex-shrink: 0;
    padding: 0.5em;
  }
}

@media screen and (min-width: 769px) {
  .cookieConsent {
    flex-direction: row;
  }
}

.issues {
  list-style-type: none;
  margin: 28px 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  li {
    padding: 0;
    margin: 0;
  }

  img {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    max-height: 30vh;
  }
}