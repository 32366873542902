:root {
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
}

.annotationLayer section {
  text-align: initial;
  position: absolute;
}

.annotationLayer .linkAnnotation > a, .annotationLayer .buttonWidgetAnnotation.pushButton > a {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .buttonWidgetAnnotation.pushButton > canvas {
  z-index: -1;
  position: relative;
  top: 0;
  left: 0;
}

.annotationLayer .linkAnnotation > a:hover, .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
  opacity: .2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}

.annotationLayer .textAnnotation img {
  cursor: pointer;
  position: absolute;
}

.annotationLayer .textWidgetAnnotation input, .annotationLayer .textWidgetAnnotation textarea, .annotationLayer .choiceWidgetAnnotation select, .annotationLayer .buttonWidgetAnnotation.checkBox input, .annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-image: var(--annotation-unfocused-field-background);
  box-sizing: border-box;
  height: 100%;
  vertical-align: top;
  width: 100%;
  border: 1px solid #0000;
  margin: 0;
  padding: 0 3px;
  font-size: 9px;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  font: message-box;
  resize: none;
  font-size: 9px;
}

.annotationLayer .textWidgetAnnotation input[disabled], .annotationLayer .textWidgetAnnotation textarea[disabled], .annotationLayer .choiceWidgetAnnotation select[disabled], .annotationLayer .buttonWidgetAnnotation.checkBox input[disabled], .annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  cursor: not-allowed;
  background: none;
  border: 1px solid #0000;
}

.annotationLayer .textWidgetAnnotation input:hover, .annotationLayer .textWidgetAnnotation textarea:hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover, .annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 1px solid #000;
}

.annotationLayer .textWidgetAnnotation input:focus, .annotationLayer .textWidgetAnnotation textarea:focus, .annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 1px solid #0000;
}

.annotationLayer .textWidgetAnnotation input :focus, .annotationLayer .textWidgetAnnotation textarea :focus, .annotationLayer .choiceWidgetAnnotation select :focus, .annotationLayer .buttonWidgetAnnotation.checkBox :focus, .annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  background-color: #0000;
  background-image: none;
  outline: auto;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after, .annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  content: "";
  background-color: #000;
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  height: 80%;
  width: 1px;
  left: 45%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  top: 20%;
  left: 30%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  padding-left: 2px;
  padding-right: 0;
  font-family: monospace;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input, .annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
}

.annotationLayer .popupWrapper {
  width: 20em;
  position: absolute;
}

.annotationLayer .popup {
  z-index: 200;
  max-width: 20em;
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  background-color: #ff9;
  border-radius: 2px;
  margin-left: 5px;
  padding: 6px;
  font-size: 9px;
  position: absolute;
  box-shadow: 0 2px 5px #888;
}

.annotationLayer .popup > * {
  font-size: 9px;
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  margin-left: 5px;
  display: inline-block;
}

.annotationLayer .popupContent {
  border-top: 1px solid #333;
  margin-top: 2px;
  padding-top: 2px;
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
}

.annotationLayer .highlightAnnotation, .annotationLayer .underlineAnnotation, .annotationLayer .squigglyAnnotation, .annotationLayer .strikeoutAnnotation, .annotationLayer .freeTextAnnotation, .annotationLayer .lineAnnotation svg line, .annotationLayer .squareAnnotation svg rect, .annotationLayer .circleAnnotation svg ellipse, .annotationLayer .polylineAnnotation svg polyline, .annotationLayer .polygonAnnotation svg polygon, .annotationLayer .caretAnnotation, .annotationLayer .inkAnnotation svg polyline, .annotationLayer .stampAnnotation, .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

body {
  color: #d5d5d5;
  background-color: #202124;
  margin: 0;
  font-family: Raleway, Segoe UI, Tahoma, sans-serif;
}

a {
  color: #019eeb;
  text-decoration: none;
}

input, button, .button {
  background-color: #aebac3;
  border: #3b3f43;
  border-radius: .4em;
  display: inline-block;
}

input.primary, button.primary, .button.primary, input.secondary, button.secondary, .button.secondary {
  color: #fff;
  padding: 1em;
  font-size: 1em;
  font-weight: bold;
}

input.primary, button.primary, .button.primary {
  background-color: #019eeb;
}

input.secondary, button.secondary, .button.secondary {
  background-color: #009e1b;
}

.welcome {
  justify-content: center;
  align-items: center;
  display: flex;
}

.welcome .container {
  text-align: center;
  padding: 1em;
}

.welcome .headerImage {
  max-width: 80vw;
}

@media screen and (min-width: 769px) {
  .welcome .container {
    width: 60vw;
  }
}

.welcome h1 {
  margin: 0;
}

.welcome .emphasis {
  font-weight: 600;
}

.magazineViewer {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.magazineViewer header {
  color: #fff;
  background-color: #323639;
  padding: 20px;
  box-shadow: 0 0 8px #00000080;
}

.magazineViewer header input, .magazineViewer header button {
  height: 2.5em;
}

.magazineViewer header input.square, .magazineViewer header button.square {
  width: 2.5em;
}

.magazineViewer header .scaling {
  text-align: center;
  position: absolute;
  top: 20px;
  left: 10%;
  right: 10%;
}

.magazineViewer header .scaling span {
  margin: 0 1em;
  display: inline-block;
}

.magazineViewer header .controls {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.magazineViewer header .controls .meta {
  flex-grow: 1;
  font-size: 1.5em;
}

.magazineViewer header .controls .meta .title {
  font-size: inherit;
  margin: 0;
  font-weight: 700;
}

.magazineViewer header .controls .meta .date {
  color: #a6b1b9;
}

.magazineViewer__pageList {
  overflow-x: hidden !important;
}

.magazineViewer__container {
  flex-grow: 1;
  position: relative;
}

.magazineViewer__container__load {
  color: #fff;
  margin-top: 1em;
}

.magazineViewer__container .page-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.magazineViewer__container .react-pdf__Document {
  position: absolute;
  inset: 0;
}

.magazineViewer__container .react-pdf__Page {
  max-width: calc(100% - 2em);
  margin: 1em;
  box-shadow: 0 0 8px #00000080;
}

.magazineViewer__container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.magazineViewer__container .react-pdf__message {
  color: #fff;
  text-align: center;
  padding: 20px;
}

.touch-onboarding {
  text-align: center;
  padding: 1em;
  display: block;
}

.icons {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.icons img {
  margin: .5em;
}

.progress {
  z-index: 3000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.progress .bar {
  height: 3px;
  width: 50%;
  background-color: #019eeb;
}

.bothAxisCenter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.cookieConsent {
  color: #fff;
  color: #cfcfcf;
  background: #323639;
  flex-direction: column;
  align-items: center;
  padding: .5em;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookieConsent > * {
  margin: .2em;
}

.cookieConsent button, .cookieConsent .button {
  flex-shrink: 0;
  padding: .5em;
}

@media screen and (min-width: 769px) {
  .cookieConsent {
    flex-direction: row;
  }
}

.issues {
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 28px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.issues li {
  margin: 0;
  padding: 0;
}

.issues img {
  max-height: 30vh;
  box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
}

/*# sourceMappingURL=index.014305a1.css.map */
